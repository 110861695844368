import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  useClearAllPersistedSegments,
  useDeletePersistedSegments,
  useSavedSegments,
  useUpdatePersistedSegments,
} from '../../../../api/Surveys';
import { MatomoEvent, SegmentType } from '../../../../constants';
import useMatomo from '../../../../hooks/useMatomo';
import useParams from '../../../../hooks/useParams';
import { queryType, questionType, segmentType } from '../../../../types';
import CreateSegmentModal from '../../CreateSegmentModal';
import EditSegmentModal from '../../EditSegmentModal';
import ManageSavedSegmentsModal from './ManageSavedSegmentsModal';
import SaveSegmentModal from './SaveSegmentModal';

const { Text, Link, Title } = Typography;

const StyledTag = styled(Tag)`
  margin-top: 8px;
  cursor: pointer;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledText = styled(Text)`
  color: ${(props) => (props.active ? '#fafafa' : '')};
`;

function SegmentThemesCard({
  segments,
  questions,
  questionResponseOptions,
  selectedQuestion,
  selectedSegmentQuestionId,
  setSelectedSegmentQuestionId,
  statAssistEnabled,
  setStatAssistEnabled,
  createPersistedSegments,
}) {
  const { trackEvent } = useMatomo();
  const { surveyId } = useParams();
  const [isCreateSegmentModalVisible, setCreateSegmentModalVisible] = useState(false);
  const [isEditSegmentModalVisible, setEditSegmentModalVisible] = useState(false);
  const [isManageSavedSegmentsModalVisible, setManageSavedSegmentsModalVisible] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(undefined);
  const [segmentToSave, setSegmentToSave] = useState(null);

  const queryClient = useQueryClient();

  const { data: savedSegments, isLoading: savedSegmentsLoading } = useQuery(
    ['savedSegments', { surveyId }],
    useSavedSegments(),
  );

  const { mutate: updatePersistedSegments } = useMutation({
    mutationFn: useUpdatePersistedSegments(),
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['persistedSegments', { surveyId }]);
      const prevSegments = queryClient.getQueryData(['persistedSegments', { surveyId }]);
      queryClient.setQueryData(['persistedSegments', { surveyId }], (prev) => ({
        ...prev,
        data: prev.data.map((s) =>
          s.id === variables.segmentId ? { ...s, ...variables.data } : s,
        ),
      }));
      return { prevSegments };
    },
    onError: (_, variables, context) => {
      queryClient.setQueryData(['persistedSegments', { surveyId }], context.prevSegments);
      message.error('Something went wrong updating segment');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['persistedSegments', { surveyId }]);
    },
  });

  const { mutate: deletePersistedSegments } = useMutation({
    mutationFn: useDeletePersistedSegments(),
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['persistedSegments', { surveyId }]);
      const prevSegments = queryClient.getQueryData(['persistedSegments', { surveyId }]);
      await queryClient.setQueryData(['persistedSegments', { surveyId }], (prev) => ({
        ...prev,
        data: prev.data.filter((s) => !variables.segmentIds.includes(s.id)),
      }));
      return { prevSegments };
    },
    onError: (_, variables, context) => {
      queryClient.setQueryData(['persistedSegments', { surveyId }], context.prevSegments);
      message.error('Something went wrong deleting segment');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['persistedSegments', { surveyId }]);
    },
  });

  const { mutate: clearAllPersistedSegments } = useMutation({
    mutationFn: useClearAllPersistedSegments(),
    onMutate: async () => {
      const segmentQuestionId = selectedSegmentQuestionId;
      setSelectedSegmentQuestionId(undefined);
      await queryClient.cancelQueries(['persistedSegments', { surveyId }]);
      const prevSegments = queryClient.getQueryData(['persistedSegments', { surveyId }]);
      await queryClient.setQueryData(['persistedSegments', { surveyId }], (prev) => {
        const allRespondentSegment = prev.data.find(
          (s) => s.segment_type === SegmentType.ALL_RESPONDENTS,
        );
        return {
          ...prev,
          data: [{ ...allRespondentSegment, active: true }],
        };
      });
      return { prevSegments, segmentQuestionId };
    },
    onError: (_, variables, context) => {
      setSelectedSegmentQuestionId(context.segmentQuestionId);
      queryClient.setQueryData(['persistedSegments', { surveyId }], context.prevSegments);
      message.error('Something went wrong clearing segments');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['persistedSegments', { surveyId }]);
    },
  });

  const handleSelectSegmentQuestion = async (questionId) => {
    setSelectedSegmentQuestionId(questionId);

    // deactivate the all respondents segment if it's active
    const allRespondentSegment = segments.find(
      (s) => s.segment_type === SegmentType.ALL_RESPONDENTS,
    );
    if (allRespondentSegment.active) {
      updatePersistedSegments({
        surveyId,
        segmentId: allRespondentSegment.id,
        data: { active: false },
      });
    }

    // delete all existing attribute segments
    const attributeSegments = segments.filter((s) => s.segment_type === SegmentType.ATTRIBUTE);
    if (attributeSegments.length) {
      deletePersistedSegments({ surveyId, segmentIds: attributeSegments.map((s) => s.id) });
    }

    // add each response option as a separate segment
    const responseOptions = questionResponseOptions.find((q) => q.data.questionId === questionId);
    const data = responseOptions?.data.data.map((option) => ({
      active: true,
      segment: { [questionId]: [option.text] },
      segment_type: SegmentType.ATTRIBUTE,
      label: option.text,
    }));
    createPersistedSegments({ surveyId, data });
  };

  const handleSaveCustomSegment = async (selectedChoices, label = null) => {
    createPersistedSegments({
      surveyId,
      data: [
        {
          active: true,
          segment: selectedChoices,
          segment_type: SegmentType.CUSTOM,
          label: label || Object.values(selectedChoices).flat().join(', '),
        },
      ],
    });
  };

  const options = questions
    .reduce(
      (acc, { text, id }) => {
        const group = selectedQuestion.significant_segments.includes(id)
          ? acc[0].options
          : acc[1].options;
        group.push({ label: text, value: id });
        return acc;
      },
      [
        { label: 'May contain meaningful theme variation', options: [] },
        { label: 'No meaningful theme variation', options: [] },
      ],
    )
    .filter((group) => group.options.length);

  const createSegmentItems = [
    {
      key: 'create',
      label: 'Create custom segment',
      onClick: () => {
        trackEvent(MatomoEvent.ADD_BUTTON_IN_SEGMENTS_CLICKS);
        setCreateSegmentModalVisible(true);
      },
    },
    {
      key: 'saved',
      label: 'Saved segments',
      children: [
        ...(savedSegments?.data.map((s) => ({
          key: s.id,
          label: s.label,
          onClick: () => handleSaveCustomSegment(s.segment, s.label),
        })) || []),
        { type: 'divider' },
        {
          key: 'manage',
          label: 'Manage saved segments',
          onClick: () => setManageSavedSegmentsModalVisible(true),
        },
      ],
    },
  ];

  return (
    <div>
      <Row justify="space-between" align="top">
        <Space>
          <Title level={5}>Segment themes</Title>
        </Space>
        <Space>
          <Link
            href="https://intercom.help/fathomthat/en/articles/6368747-creating-segments"
            target="_blank"
            rel="noreferrer noopener"
          >
            How to use segments
          </Link>
        </Space>
      </Row>
      <Card size="small">
        <Row gutter={8} wrap={false}>
          <Col flex="auto">
            <StyledSelect
              placeholder="Please select a closed ended or demographic question"
              value={selectedSegmentQuestionId}
              onChange={handleSelectSegmentQuestion}
              showArrow
              options={options}
            />
            <Row>
              {segments.map((segment) => (
                <StyledTag key={segment.id} color={segment.active ? '#1890FF' : ''}>
                  <Space>
                    <StyledText
                      active={segment.active ? 1 : 0}
                      onClick={() =>
                        updatePersistedSegments({
                          surveyId,
                          segmentId: segment.id,
                          data: { active: !segment.active },
                        })
                      }
                    >
                      {segment.label}
                    </StyledText>
                    <EditOutlined
                      onClick={() => {
                        setSelectedSegment(segment);
                        setEditSegmentModalVisible(true);
                      }}
                    />
                  </Space>
                </StyledTag>
              ))}
            </Row>
          </Col>
          <Col flex="none">
            <Space direction="vertical" align="end">
              <Space>
                {savedSegments?.data.length ? (
                  <Dropdown menu={{ items: createSegmentItems }}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={(e) => e.preventDefault()}
                      loading={savedSegmentsLoading}
                    >
                      Custom segment
                    </Button>
                  </Dropdown>
                ) : (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      trackEvent(MatomoEvent.ADD_BUTTON_IN_SEGMENTS_CLICKS);
                      setCreateSegmentModalVisible(true);
                    }}
                  >
                    Custom segment
                  </Button>
                )}
                <Button
                  type="primary"
                  onClick={() => clearAllPersistedSegments({ surveyId })}
                  danger
                  disabled={segments.length <= 1}
                >
                  Clear all
                </Button>
              </Space>
              {selectedQuestion.significant_segments.includes(selectedSegmentQuestionId) &&
                segments.every((s) =>
                  s.active ? s.segment_type === SegmentType.ATTRIBUTE : true,
                ) && (
                  <Space data-cy="stat-assist-toggle">
                    Stat Assist
                    <Switch
                      size="small"
                      checked={statAssistEnabled}
                      onChange={setStatAssistEnabled}
                    />
                  </Space>
                )}
            </Space>
          </Col>
        </Row>
      </Card>
      <EditSegmentModal
        visible={isEditSegmentModalVisible}
        setVisible={setEditSegmentModalVisible}
        segment={selectedSegment}
        editSegmentName={(id, label) =>
          updatePersistedSegments({
            surveyId,
            segmentId: id,
            data: { label },
          })
        }
        removeSegment={(id) => deletePersistedSegments({ surveyId, segmentIds: [id] })}
      />
      <CreateSegmentModal
        visible={isCreateSegmentModalVisible}
        setVisible={setCreateSegmentModalVisible}
        questions={questions}
        questionResponseOptions={questionResponseOptions}
        saveSegment={handleSaveCustomSegment}
        setSegmentToSave={setSegmentToSave}
      />
      <SaveSegmentModal
        surveyId={surveyId}
        segmentToSave={segmentToSave}
        setVisible={() => setSegmentToSave(undefined)}
        saveSegment={handleSaveCustomSegment}
      />
      <ManageSavedSegmentsModal
        visible={isManageSavedSegmentsModalVisible}
        setVisible={setManageSavedSegmentsModalVisible}
        surveyId={surveyId}
      />
    </div>
  );
}

SegmentThemesCard.defaultProps = {
  selectedSegmentQuestionId: undefined,
  segments: [],
};

SegmentThemesCard.propTypes = {
  segments: PropTypes.arrayOf(segmentType),
  questions: PropTypes.arrayOf(questionType.isRequired).isRequired,
  questionResponseOptions: PropTypes.arrayOf(queryType).isRequired,
  selectedQuestion: questionType.isRequired,
  selectedSegmentQuestionId: PropTypes.number,
  setSelectedSegmentQuestionId: PropTypes.func.isRequired,
  statAssistEnabled: PropTypes.bool.isRequired,
  setStatAssistEnabled: PropTypes.func.isRequired,
  createPersistedSegments: PropTypes.func.isRequired,
};

export default SegmentThemesCard;
